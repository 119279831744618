<template>
  <v-container fluid style="height: 100%; display: flex; flex-direction: column">
    <page-title
      title="Projects<sup><i class='fas fa-circle-e fs-14px teal--text ml-1 mr-2'></i></sup>"
      :badge="total"
    />
    <v-row class="mt-0 mb-1" justify="space-between" align="center">
      <v-col sm="12" md="3" cols="12" class="d-flex align-start" style="gap: 0.5rem"> </v-col>
      <v-col
        md="9"
        sm="12"
        cols="12"
        class="d-flex flex-row align-center justify-end flex-wrap pt-0"
        style="gap: 0.5rem"
      >
        <columns-visibility-control
          :defaults="defaultHeaders"
          :withColumnsOrder="true"
          v-model="headers"
          :storageKey="storageKey"
        />
        <v-text-field
          v-model="options.search"
          label="Search"
          ref="mainSearch"
          class="table-search-field"
          @focus="mainSearchInFocus = true"
          @blur="mainSearchInFocus = false"
          :style="{
            'max-width':
              mainSearchInFocus || (options.search != '' && options.search != null)
                ? '200px'
                : '110px',
          }"
          dense
          solo
          hide-details
          clearable
          prepend-inner-icon="far fa-search"
          :loading="loadingStates.table"
        >
        </v-text-field>
        <refresh :loading="loadingStates.table" @refresh="getDataDebounced()"></refresh>
      </v-col>
    </v-row>
    <v-data-table
      style="
        overflow: auto;
        overflow: overlay;
        height: 100%;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      "
      dense
      :headers="tableHeader"
      :items="entities"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page="options.itemsPerPage"
      :loading="loadingStates.table"
      class="elevation-2 projects-table table-sticky-header-exclude-footer"
      @click:row="rowClicked"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template v-slot:progress>
        <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
      </template>

      <template v-slot:[`header.jiraCode`]="{ header }">
        <i :class="header.icon"></i>
        {{ header.text.toUpperCase() }}
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <h4>{{ item.fullName }}</h4>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <project-status :status="item.status" small show-parent></project-status>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon elevation="0" class="mr-1" @click.stop="update(item.id)">
          <i class="fal fa-pen"></i>
        </v-btn>
      </template>

      <template v-slot:no-data>
        <img width="500" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>

      <template v-slot:loading>
        <video width="250" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Searching the Cosmos...</p>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import perms from "../../../../plugins/permissions";
import ApiService from "../services/projects-service.js";
import ProjectStatus from "../components/ProjectStatus.vue";
import Enums from "../../../../plugins/enums";
import projectHeader from "../config/tables/project.header";

export default {
  data() {
    return {
      perms: perms,
      enums: Enums,
      activeFilters: { projectStatus },
      storageKey: "ExternalProjects",
      entities: [],
      selected: {},
      total: 0,
      projectsTimerId: -1,
      valid: false,
      mainSearchInFocus: false,
      options: {
        id: null,
        search: null,
        sortBy: ["createDate"],
        sortDesc: [true],
        ClientId: [],
        Status: [],
        createdByIds: [],
        updatedByIds: [],
      },
      modals: {
        update: false,
      },
      loadingStates: {
        table: false,
      },
      defaultHeaders: [],
      headers: projectHeader,
    };
  },
  computed: {
    tableHeader() {
      return this.headers.filter((elm) => !elm.hidden);
    },
    validProjectStatusList() {
      var statusFlat = Enums.PROJECT_STATUS_FLAT_LIST.filter(
        (s) => s.SUB_STATUS == null || s.SUB_STATUS.length == 0
      );
      return this.$options.filters.EnumToList(statusFlat, true);
    },
  },
  created() {
    this.defaultHeaders = this.headers;
    if (this.$route.query.pq) this.options.search = this.$route.query.pq;
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  mounted() {
    document.querySelector("main.v-main").classList.add("sticky-main-fix");
    window.addEventListener("popstate", this.checkRouteQuery, false);
    this.$refs.mainFilter.focus();
    this.setupFilters();
  },
  beforeDestroy() {
    document.querySelector("main.v-main").classList.remove("sticky-main-fix");
    window.removeEventListener("popstate", this.checkRouteQuery);
  },
  methods: {
    rowClicked(row) {
      this.$log("rowClicked", row);
      this.update(row.id);
    },
    getDataDebounced() {
      if (this.projectsTimerId == -1) {
        this.projectsTimerId = -1;
        this.updateRouterLink();
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.projectsTimerId);

      // delay new call 400ms
      this.projectsTimerId = setTimeout(() => {
        this.updateRouterLink();
        this.getData();
      }, 400);
    },
    getData() {
      this.loadingStates.table = true;
      this.$log("getData()", this.$clean(this.options, true));
      this.$backToTop(0, document.querySelector(".projects-table .v-data-table__wrapper"));
      ApiService.query(this.$clean(this.options, true))
        .then((resp) => {
          this.entities = resp.data.items;
          this.$log("this.entities", this.entities);
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
    checkRouteQuery(evt) {
      this.$log("checkRouteQuery", this.$route.query.pq, evt);
      if (this.$route.query.pq) this.options.search = this.$route.query.pq;
      else this.options.search = null;
    },
    updateRouterLink() {
      this.$log("getDataDebounced", this.options.search);
      if (this.$route.query.pq == this.options.search) return;
      this.$log("Routing");
      if (this.options.search != null && this.options.search != "") {
        this.$log("PUSH ROUTE", this.options.search);
        this.$router.push({
          path: this.$route.path,
          query: { pq: this.options.search },
          replace: true,
        });
      } else
        this.$router.push({
          path: this.$route.path,
          replace: true,
        });
    },
    add() {
      this.modals.add = true;
      setTimeout(() => {
        this.$refs.updateForm.resetValidation();
      });
      this.selected = {};
    },
    addConfirmed() {
      ApiService.add(this.selected)
        .then((resp) => {
          this.modals.add = false;
          var message = "Item updated successfully";
          if (!this.selected.id) {
            this.addToArr(this.entities, resp.data);
            message = "Item added successfully";
          } else {
            this.updateArr(this.entities, resp.data);
          }

          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .catch((err) => {
          this.$handleError(err);
        });
    },
    update(id) {
      // this.selected = { ...this.entities.filter((e) => e.id == id)[0] };
      // this.modals.update = true;
      if (this.options.search != null && this.options.search != "") {
        this.$router.push({
          name: `view-project`,
          params: { id: id },
          query: { pq: this.options.search },
        });
      } else {
        this.$router.push({
          name: `view-project`,
          params: { id: id },
        });
      }
    },
    updateConfirmed() {
      ApiService.update(this.selected)
        .then((resp) => {
          this.modals.update = false;
          var message = "Item updated successfully";
          if (!this.selected.id) {
            this.addToArr(this.entities, resp.data);
            message = "Item added successfully";
          } else {
            this.updateArr(this.entities, resp.data);
          }

          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .catch((err) => {
          this.$handleError(err);
        });
    },
    discard() {
      this.modals.add = false;
      this.modals.update = false;
      this.selected = {};
    },
  },
  watch: {
    options: {
      handler(val) {
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, val.itemsPerPage);
        this.getDataDebounced();
      },
      deep: true,
    },
  },
  components: { ProjectStatus },
};
</script>
<style lang="scss">
.projects-table {
  tbody tr:not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }
  // &.custom-sticky-header {
  //   .v-data-table-header {
  //     top: 40px;
  //   }
  // }
}

.v-list.project-status-filter {
  // .v-list-item {
  //   padding-top: 0.5rem;
  //   padding-bottom: 0.5rem;
  // }
  .v-list-item:not(:last-child) {
    border-bottom: thin solid rgba($shades-black, 0.1);
  }
}
</style>
